import { config } from 'config';
import {useContext, useEffect, useState} from "react";
import {PlayButton} from "utils/PlayButton";
import BitmovinPlayer from 'utils/BitmovinPlayer';
import $ from "jquery";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../../contexts/AppContext";
import liveImage from '../../../assets/images/whait-for-live.jpeg'

const TeaserLiveBody = ({stream_id, title, metadata, description, description_en, thumbnail, media_package_input_url,
                            title_en, channel_url}) => {
    const dataLang = useContext(AppContext)

    const [vodUrl, setVodUrl] = useState('');
    const [bitmovinPlayerConfig, setBitmovinPlayerConfig] = useState();
    const [isPlayerConfigLoaded, setIsPlayerConfigLoaded] = useState(false);
    const [ponente, setPonente] = useState(false);
    const [duration, setDuration] = useState(false);


    useEffect(() => {
        setVodUrl(`${media_package_input_url}`);
        if (metadata.Ponente !== undefined) {
            if (metadata.Ponente.length > 2) {
                setPonente(true)
            }
        }
        if (metadata.Duracion !== undefined) {
            setDuration(true)
        }
        handlerShowLivePlayer()
    }, []);

    const handlerShowLivePlayer = () => {
        setIsPlayerConfigLoaded(false)
        const playerConfig = getPlayerConfig({stream_id, title, media_package_input_url});
        setBitmovinPlayerConfig(playerConfig);

        $([document.documentElement, document.body]).animate({
            scrollTop: $("#bitmovin-player-container").offset().top
        }, 500, function () {
            setIsPlayerConfigLoaded(true)
        });
    }

    const getPlayerConfig = ({stream_id, title, media_package_input_url}) => {
        console.log(media_package_input_url)
        let configTile = ""
        if (dataLang.langSpanish){
            configTile = title
        } else {
            configTile = title_en
        }
        return {
            "playerConfig": {
                "analytics": {
                    "videoId": `TecMilenio-WEB-${stream_id}`,
                    "videoTitle": `TecMilenio-WEB-${configTile}`,
                    "customData1": `${config.tenant_id}`, // Cambio de customData1 el 26 de enero el dato anterior queda en customData2
                    "key": "045e1f18-e0b3-4211-83ff-6730d6906c48",
                    "customData2": `TecMilenio-WEB-VOD`,
                    "customData3": `TecMilenio-WEB-${stream_id}`
                },
                "key": "7d43a709-fd2e-43f5-a35f-1f916c8425bf",
                "playback": {
                    "autoplay": "true"
                },
                "remotecontrol": {
                    type: 'googlecast'
                },
            },
            "playerSource": {
                "hls": "https://d2wpdsklqi2ekh.cloudfront.net/out/v1/e0bd5a59155e4cc4ac569413d845245e/index.m3u8",
                'poster': 'https://d2sdk1g41rkzfz.cloudfront.net/live-image.jpeg'
            },
            "viewMode": "Fullscreen"
        }
    }

    const TeaserBody = () => {
        return (
            <>
                <div className="item teaser-item"
                     style={{backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.73)),url(${config.cloudfront_url}/${encodeURI(thumbnail)})`}}>
                    <div className="gen-movie-contain h-100">
                        <div className="container h-100">
                            <div className="row align-items-center h-100">
                                <div className="col-xl-6 pl-4">
                                    <div className='teaser-container'>
                                        <div className="gen-tag-line"><span></span></div>
                                        <div className="gen-movie-info">
                                            <h3>{dataLang.langSpanish ? title : title_en }</h3>
                                        </div>
                                        <div className="gen-movie-meta-holder">

                                            {description ? <></> : <p className='small-description'>
                                                {dataLang.langSpanish ? description : description_en }</p>}
                                        </div>
                                        <div className="gen-extra-data">
                                            <ul>
                                                <li>{duration ?
                                                    <>
                                                        <span><FormattedMessage
                                                            id="vodDuration"
                                                            defaultMessage="Duración"
                                                        />: </span>
                                                        <span> {metadata.Duracion}</span>
                                                    </> :
                                                    <></>
                                                }
                                                </li>
                                                <li>
                                                    {ponente ? <>
                                                        <span><FormattedMessage
                                                            id="vodSpeaker"
                                                            defaultMessage="Ponente"
                                                        />: </span>
                                                        <span> {metadata.Ponente}</span>
                                                    </> : <></>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gen-movie-action">
                                            <div className="gen-btn-container button-1">
                                                <PlayButton button_style={'rectangle'}
                                                            handlerShowLivePlayer={handlerShowLivePlayer}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const PlayerBody = () => {
        return (
            <section className="gen-section-padding-2 gen-single-movie pt-0 mt-0">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="gen-single-movie-wrapper style-1">
                                <div className="row">
                                    <div className='col-12'>
                                        <h4 className="gen-title default-text">{ dataLang.langSpanish ? title : title_en }</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="gen-video-holder">
                                            <BitmovinPlayer playerConfig={bitmovinPlayerConfig}/>
                                        </div>
                                        <div className="gen-single-movie-info">
                                            <h2 className="gen-title">{ dataLang.langSpanish ? title : title_en }</h2>
                                            <div className="gen-single-meta-holder">

                                                <ul>
                                                    <li>{duration ?
                                                        <>
                                                            <span>
                                                                <FormattedMessage
                                                                id="vodDuration"
                                                                defaultMessage="Duración"
                                                            />: </span>
                                                            <span> {metadata.Duracion}</span>
                                                        </> :
                                                        <></>
                                                    }
                                                    </li>
                                                    <li>
                                                        {ponente ?
                                                            <>
                                                                <span><FormattedMessage
                                                                    id="vodSpeaker"
                                                                    defaultMessage="Ponente"
                                                                />: </span>
                                                                <span>{`  ${metadata.Ponente}`}</span>
                                                            </> :
                                                            <></>
                                                        }
                                                    </li>
                                                </ul>

                                            </div>
                                            {dataLang.langSpanish ? <p>{description}</p> : <p>{description_en}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <div>
            {isPlayerConfigLoaded ? (
                <PlayerBody/>
            ) : (
                <TeaserBody/>
            )}
        </div>
    )
}
export default TeaserLiveBody;
