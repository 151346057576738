import {useContext, useEffect, useState} from 'react';
import {AppContext} from "contexts/AppContext";
import useAuth from "hooks/useAuth";
import {config} from 'config';
import {NavLink} from "react-router-dom";
import axios from "axios";
import {FormattedMessage} from "react-intl";

const EventLive = () => {

    const {user} = useAuth();
    const [isLoggedInUser, setLoggedInUser] = useState(false);
    const {modalLoginWindow, dispatchModalWindow} = useContext(AppContext);

    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(true);
            return;
        }
        //setLoggedInUser(false);
        setLoggedInUser(true);
    }, [user]);

    const handlePlayWithoutLogin = () => {
        const action = {
            type: '[MODAL] Open Modal',
            payload: ''
        }
        dispatchModalWindow(action)
    }

    const [liveItemOn, setLiveItemOn] = useState(false);
    const [url, setUrl] = useState('/teaser/live/#')

    const get_live_data = async () => {
        axios["get"](`aos-channel/list/modified?tenant_id=${config.tenant_id}`)
            .then((res) => {
                const public_see = res.data.public_see
                console.log(public_see)
                if (public_see === 1) {
                    setUrl(`/teaser/live/${res.data.stream_id}`)
                    setLiveItemOn(true);
                } else {
                    setLiveItemOn(false)

                }

            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {

            });
    }

    function run_get_live() {
        get_live_data()
    }

    useEffect(() => {
        get_live_data()
        setInterval(() => run_get_live(), 60000);

    }, []);

    const LiveButton = () => {
        return (
            <>
                <li className="d-none d-lg-block">
                    <NavLink
                        className='button-red'
                        to={url}
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="navLive"
                            defaultMessage="En vivo"
                        />
                    </NavLink>
                </li>
                <li className="d-lg-none menu-item">
                    <NavLink
                        to={url}
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="navLive"
                            defaultMessage="En vivo"
                        />
                    </NavLink>
                </li>
            </>
        )
    }

    const LoginButton = () => {
        return (
            <>
                <a className='d-none d-lg-block login-button-red'
                   onClick={handlePlayWithoutLogin}
                   data-toggle="collapse"
                   data-target="#navbarSupportedContent">
                    <FormattedMessage
                        id="navLive"
                        defaultMessage="En vivo"
                    />
                </a>
                <li className="d-lg-none menu-item">
                    <a
                        onClick={handlePlayWithoutLogin}
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="navLive"
                            defaultMessage="En vivo"
                        />
                    </a>
                </li>
            </>
        )
    }

    return (
        <>
            {(liveItemOn) ? (
                <>
                    {(isLoggedInUser ? <LiveButton/> : <LoginButton/>)}
                </>
            ) : (
                <></>
            )}
        </>

    )
}
export default EventLive;
