import {useEffect, useState} from 'react';
import useAxios from 'hooks/useAxios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SlideItem from './SlideItem';
import {config} from 'config';
import eventImage from "../../../assets/images/banner_event_wellbeing_tv.jpg";
import wellbeingWeekImage from "../../../assets/images/SemanadelBienestarBanner.png";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const SliderFull = ({category_id, category_name_for_show, header = false}) => {

    const [posterItems, setPosterItems] = useState([]);
    const [wellbeingWeek, setWellbeingWeek] = useState(false)
    const event = false

    const {response, loading, error} = useAxios({
        // aos-category/parent_id/8e6c242d4ca841409c68a1e4079ba4ad
        method: 'get',
        url: `aos-vod/category/${category_id}?tenant_id=tecmilenio&category_id=${category_id}`
    });

    useEffect(() => {
        if (response !== null) {
            let list = []
            for (const video of response.category_list) {
                if (video.public_see === 1) {
                    list.push(video)
                }
            }
            setPosterItems(list);
        }
    }, [response]);

    const Slider = () => {

        const Slides = posterItems.map((poster) =>
            <SlideItem key={poster.asset_id} {...poster} category_name_for_show={category_name_for_show}/>
        );

        return (
            <OwlCarousel className='owl-carousel owl-loaded owl-drag' {...config.owlCarousel.full_slider.options}>
                {event ?
                    <div className="item" style={{backgroundImage: `url(${eventImage})`}}>
                        <div className="gen-movie-contain h-100">
                            <div className="container h-100">
                                <div className="row align-items-center h-100">

                                    <div className="col-xl-6">
                                        <div className="gen-tag-line"><span></span></div>

                                        <div className="gen-movie-action">
                                            <div className="gen-btn-container button-1">
                                                <a href='https://www.wellbeing360.tv/live/index.html'
                                                   className="gen-buttonConMargin">
                                                    <span className="text">
                                                        <FormattedMessage
                                                            id="buttonLearnmore"
                                                            defaultMessage="Ver Ahora"
                                                        />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <></>}
                {wellbeingWeek ?
                    <div className="item" style={{backgroundImage: `url(${wellbeingWeekImage})`}}>
                        <div className="gen-movie-contain h-100">
                            <div className="container h-100">
                                <div className="row align-items-center h-100">

                                    <div className="col-xl-6">
                                        <div className="gen-tag-line"><span></span></div>

                                        <div className="gen-movie-action">
                                            <div className="gen-btn-container button-1">
                                                <Link to={`/category/77551789163649c6a8d18bb344d602ae`}
                                                      className="gen-button">
                                        <span className="text">
                                            <FormattedMessage
                                                id="buttonWatchNow"
                                                defaultMessage="Ver Ahora"
                                            /></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <></>}

                {Slides}
            </OwlCarousel>
        )
    }

    const SliderHeader = () => {
        if (header) {
            return (
                <div className="container space">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <h4 className="gen-heading-title text-uppercase">{category_name_for_show}</h4>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <section className="pt-0 pb-0">
            <div>
                <SliderHeader/>
                <div className="container-fluid px-10">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="gen-banner-movies">
                                <Slider/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SliderFull;
