import useAuth from "../../hooks/useAuth";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {config} from "../../config";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../contexts/AppContext";
import ReactPixel from "react-facebook-pixel";

const PrivacyPolicyWeek = () => {
    const dataLang = useContext(AppContext)
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: `/privacy-policy-week`, title: `Wellbeing 360 - WEEK-PRIVACY-POLICY`});
        ReactPixel.track(`WEEK-PRIVACY-POLICY`);
    }, []);
    return (
        <div className="container container-privacy-policy">
            <h3 className="purple-text">
                <FormattedMessage
                    id="privacyTitle"
                    defaultMessage="AVISO DE PRIVACIDAD PARA PARTICIPANTES WELLBEING 360"
                /></h3>
            <br/>
            <div className="content-justify">
                <h5 className="url-color">
                    <FormattedMessage
                        id="privacyIdentity"
                        defaultMessage="Identidad y domicilio del Responsable"
                    /></h5>
                <p><FormattedMessage
                    id="privacyIdentityParagraph"
                    defaultMessage="El Responsable de los datos personales que usted proporciona es Enseñanza e Investigación Superior, A.C. (en adelante Universidad Tecmilenio) ubicado en Av. Eugenio Garza Sada No. 2501 Sur, colonia Tecnológico, Monterrey, Nuevo León, C.P. 64849."
                />
                </p>

                <h5 className="url-color"><FormattedMessage
                    id="privacyContact"
                    defaultMessage="Datos de contacto del Departamento de Protección de Datos Personales"
                /></h5>
                <p><FormattedMessage
                    id="privacyContactParagraph"
                    defaultMessage="Para cualquier duda relacionada con la protección de sus datos personales podrá contactar al Departamento de Protección de Datos Personales de Universidad Tecmilenio.
                    Los datos de contacto del Departamento de Protección de Datos Personales son los siguientes: Paseo del Acueducto No. 2610, colonia Del Paseo Residencial, Monterrey, Nuevo León, C.P. 64909. Correo electrónico: aviso.privacidad@servicios.tecmilenio.mx"
                />
                </p>
                <h5 className="url-color"><FormattedMessage
                    id="privacyPersonalInformation"
                    defaultMessage="Datos personales y datos personales sensibles tratados por la Universidad Tecmilenio"
                /></h5>
                <p><FormattedMessage
                    id="privacyPersonalInformationParagraph"
                    defaultMessage="Universidad Tecmilenio tratará datos personales de Identificación, datos personales de Contacto, datos personales Laborales, Edad, Campus, Rol(colaborador, Estudiante o externos), además se incluyendo su imagen personal a través de video, el cual va ser de manera obligatoria para participar en la semana de bienestar integral,  para cumplir con las finalidades señaladas en el presente aviso de privacidad.
                    Asimismo, le informamos que para cumplir con las finalidades descritas en este aviso de privacidad no se tratarán datos personales sensibles, que requieren de especial protección. Le informamos que el tratamiento de dichos datos es necesario para cumplir las obligaciones derivadas de la relación jurídica entre usted y Universidad Tecmilenio, razón por la cual su consentimiento se encuentra exceptuado."
                />
                </p>
                <ul>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint1"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint2"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint3"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint4"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint5"
                            defaultMessage=""
                        />
                    </li>
                </ul>
                <p><FormattedMessage
                    id="privacyPersonalInformationParagraph2"
                    defaultMessage=""
                />
                </p>
                <h5 className="url-color"><FormattedMessage
                    id="privacyPrimary"
                    defaultMessage="Finalidades primarias"
                /></h5>
                <p><FormattedMessage
                    id="privacyPrimaryParagraph"
                    defaultMessage="Universidad Tecmilenio. tratará sus datos personales para las siguientes finalidades primarias y necesarias:
                    •	Para administrar su participación en la Semana del bienestar integral.
                    •	Para identificarle y registrarle como usuario en nuestra plataforma Wellbeing 360 TV
                    •	Para que su imagen personal ,  través  del video, sea utilizada en Wellbeing 360 TV, con el fin de que sea visualizado por terceras personas su participación en la semana del bienestar integral
                    •	Para brindarle asistencia técnica sobre la plataforma en caso que usted así lo solicite.
                    Le informamos que usted no puede oponerse para que Universidad Tecmilenio cese el tratamiento de sus datos para las finalidades primarias y necesarias, anteriormente mencionadas, en virtud de que el tratamiento es necesario para cumplir obligaciones derivadas de la relación entre usted y Universidad Tecmilenio."
                />
                </p>
                <ul>
                    <li>
                        <FormattedMessage
                            id="privacyPrimaryPoint1"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint2"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint3"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint4"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacyPersonalInformationPoint5"
                            defaultMessage=""
                        />
                    </li>
                </ul>

                <p><FormattedMessage
                    id="privacyPrimaryParagraph2"
                    defaultMessage=""
                />
                </p>
                <h5 className="url-color">
                    <FormattedMessage
                        id="privacySecondary"
                        defaultMessage="Finalidades secundarias"
                    /></h5>
                <p><FormattedMessage
                    id="privacySecondaryParagraph"
                    defaultMessage="Además, si usted no se opone, Universidad Tecmilenio tratará sus datos personales para las siguientes finalidades adicionales que no son necesarias para el servicio solicitado, pero que permiten y facilitan brindarle una mejor atención:
                    •	Para enviarle información promocional y eventos relacionados con la Semana del bienestar integral
                    •	Para la aplicación de encuestas y evaluaciones para mejorar la calidad de los productos y servicios que ofrecemos.
                    •	Para promocionar la vida académica, de investigación, de extensión, los eventos deportivos, culturales, recreativos y sociales que realiza “Universidad Tecmilenio”.
                    En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo aviso.privacidad@servicios.tecmilenio.mx
                    La negativa para el uso de sus datos personales para fines adicionales no podrá ser un motivo para negarle los servicios o productos que contrata o solicita ni para dar por terminada la relación establecida con nosotros."
                />
                </p>
                <ul>
                    <li>
                        <FormattedMessage
                            id="privacySecondaryPoint1"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacySecondaryPoint2"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacySecondaryPoint3"
                            defaultMessage=""
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="privacySecondaryPoint4"
                            defaultMessage=""
                        />
                    </li>
                </ul>
                <p><FormattedMessage
                    id="privacySecondaryParagraph2"
                    defaultMessage="
                    En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo aviso.privacidad@servicios.tecmilenio.mx
                    La negativa para el uso de sus datos personales para fines adicionales no podrá ser un motivo para negarle los servicios o productos que contrata o solicita ni para dar por terminada la relación establecida con nosotros."
                />
                </p>
                <h5 className="url-color"><FormattedMessage
                    id="privacyTransfers"
                    defaultMessage="TRANSFERENCIAS"
                /></h5>
                <p><FormattedMessage
                    id="privacyComply"
                    defaultMessage="Universidad Tecmilenio para cumplir las finalidades necesarias anteriormente descritas u otras aquellas exigidas legalmente o por las autoridades competentes transferirá los datos personales necesarios a las siguientes organizaciones y para los siguientes fines:
                    Tercero receptor de los datos personales	Finalidad	Consentimiento
                    Empresas terceras que tengan celebrados convenios o contratos con Universidad Tecmilenio	Para informar de la participación de los usuarios en la plataforma de la Semana del bienestar integral	Necesario

                    Si usted no desea que Universidad Tecmilenio transfiera sus datos personales para aquellas transferencias para las cuales es necesario su consentimiento le pedimos que envíe un correo electrónico a la dirección aviso.privacidad@servicios.tecmilenio.mx, en donde se le atenderá en tiempo y forma."
                />
                </p>


                <h5 className="url-color"><FormattedMessage
                    id="privacyRights"
                    defaultMessage="Derechos ARCO y/o revocación del consentimiento"
                /></h5>
                <p><FormattedMessage
                    id="privacyLegal"
                    defaultMessage="Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (“Derechos ARCO”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos Personales a la dirección electrónica aviso.privacidad@servicios.tecmilenio.mx.
                    En este sentido, puede informarse sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento en nuestra página de internet http://tecmilenio.mx/aviso-de-privacidad/derechos-arco/, o bien ponerse en contacto con el Departamento de Protección de Datos Personales que dará trámite a las solicitudes para el ejercicio de estos derechos, y atenderá cualquier duda que pudiera tener respecto al tratamiento de su información. Los datos de contacto del Departamento de Protección de Datos Personales son los siguientes: Paseo del Acueducto No. 2610, colonia Del Paseo Residencial, Monterrey, Nuevo León, C.P. 64909. Teléfono: (81) 8305 3200, Ext. 4893. Correo electrónico: aviso.privacidad@servicios.tecmilenio.mx."
                />
                </p>
                <h5 className="url-color"><FormattedMessage
                    id="privacyLimitation"
                    defaultMessage="Limitación y/o Divulgación de sus datos"
                /></h5>
                <p><FormattedMessage
                    id="privacyLimitationParagraph"
                    defaultMessage="Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud al aviso.privacidad@servicios.tecmilenio.mx. En caso de que su solicitud sea procedente se le registrará en el listado de exclusión propio de Universidad Tecmilenio."
                /></p>
                <h5 className="url-color"><FormattedMessage
                    id="privacyCookies"
                    defaultMessage="Cambios al Aviso de Privacidad"
                /></h5>
                <p><FormattedMessage
                    id="privacyCookiesParagraph"
                    defaultMessage="Universidad Tecmilenio le notificará sobre cambios o actualizaciones al presente aviso de privacidad directamente al sitio web https://www.wellbeing360.tv/"
                />

                </p>
                <h5 className="url-color"><FormattedMessage
                    id="privacyChanges"
                    defaultMessage="Cambios al Aviso de Privacidad"
                /></h5>
                <p><FormattedMessage
                    id="privacyNotification"
                    defaultMessage="Universidad Tecmilenio le notificará sobre cambios o actualizaciones al presente aviso de privacidad directamente al sitio web https://www.wellbeing360.tv/"
                />

                </p>
                <p className="date-policy"><FormattedMessage
                    id="privacyUpdate"
                    defaultMessage="Última actualización: 21/02/2023"
                /></p>

            </div>
        </div>

    )
}

export default PrivacyPolicyWeek
